import React from 'react'
import Breadcrumbs from '../components/breadcrumbs'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import PrenotaVisitaTabs from '../components/prenota-visita-tabs'
import DoveSiamo from '../components/dove-siamo'
import Layout from "../components/layout"
import { graphql } from "gatsby"


class EmergenzaCavalli extends React.Component {

  render() { 
    const breadcrumb = [{"title":"Prenota visita cavalli"}];
    const intro = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    const datahero = {
      "title": "Ospedale Veterinario Universitario",
      "subtitle": "PRENOTA UNA VISITA",
      "img": hero.childImageSharp.fluid,
      "arealink": false 
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
           title="Prenota visita cavalli"
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero}/>
        <PrenotaVisitaTabs  data="cavalli"/>
        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <h1>Prenota una visita</h1>
              {!!intro && 
                <div className="bodytext" dangerouslySetInnerHTML={{ __html: intro.abstract.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
             
              <DoveSiamo/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default EmergenzaCavalli;

export const query = graphql`
query EmergenzaCavalliQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "3u8XLlFQJGck2wsUc4ym0a"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
  }
  hero: file(relativePath: {eq: "hero-studenti.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
        src
      }
    }
  }
}
`